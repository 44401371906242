/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */

.rule-desc,
.markdown {
  line-height: 1.5;
}

.rule-desc p,
.markdown p,
.rule-desc ul,
.markdown ul,
.rule-desc ol,
.markdown ol,
.rule-desc pre,
.markdown pre,
.rule-desc blockquote,
.markdown blockquote,
.rule-desc table,
.markdown table,
.rule-desc h2,
.markdown h2,
.rule-desc h3,
.markdown h3,
.rule-desc h4,
.markdown h4,
.rule-desc h5,
.markdown h5,
.rule-desc h6,
.markdown h6 {
  margin: 1em 0;
}

.markdown h2 {
  font-size: 16px;
  font-weight: 400;
}

.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  font-size: 13px;
  font-weight: 600;
}

.rule-desc h2 {
  font-size: 22px;
  font-weight: 500;
  border-bottom: 1px solid #e6e6e6;
  line-height: 2rem;
  margin-top: 32px;
  margin-bottom: 16px;
}

.rule-desc *:first-child {
  margin-top: 0;
}

.rule-desc h3 {
  font-size: 18px;
  font-weight: 700;
}
.rule-desc h4 {
  font-size: 16px;
  font-weight: 500;
}

.rule-desc h5,
.rule-desc h6 {
  font-size: 13px;
  font-weight: 600;
}

.rule-desc pre,
.markdown pre,
.rule-desc code,
.markdown code {
  font-family: Consolas, 'Ubuntu Mono', 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 12px;
}

.rule-desc pre,
.markdown pre {
  background-color: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  padding: 16px;
  margin-top: 0;
  margin-bottom: 0;
  overflow-x: auto;
}

.rule-desc code,
.markdown code,
code.rule {
  padding: 0.2em 0.45em;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  white-space: nowrap;
}

.rule-desc pre > code,
.markdown pre > code {
  padding: 0;
  background-color: transparent;
  white-space: pre;
}

.rule-desc blockquote,
.markdown blockquote {
  line-height: 1.5;
  padding-left: 15px;
  padding-right: 15px;
}

.rule-desc ul,
.markdown ul {
  padding-left: 40px;
  list-style: disc;
}

.rule-desc li > ul,
.markdown li > ul {
  margin: 0.3em 0;
}

.rule-desc ol,
.markdown ol {
  padding-left: 40px;
  list-style: decimal;
}

.rule-desc table,
.markdown table {
  min-width: 50%;
  border-collapse: collapse;
  border: 1px solid #e6e6e6;
}

.rule-desc th,
.markdown th {
  padding: 5px 10px;
  border: 1px solid #e6e6e6;
  background-color: #f3f3f3;
  font-weight: 600;
  text-align: center;
}

.rule-desc td,
.markdown td {
  padding: 5px 10px;
  border: 1px solid #e6e6e6;
}
