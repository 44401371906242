/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.global-loading {
  width: 300px;
  margin: 200px auto 0;
  white-space: nowrap;
  font-family:
    Inter,
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  color: rgb(62, 67, 87);
}

.global-loading-spinner {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  width: 80px;
  height: 80px;
  border: 2px solid transparent;
  border-radius: 625rem;
  background:
    linear-gradient(0deg, rgb(93, 108, 208) 50%, transparent 50% 100%) border-box,
    linear-gradient(90deg, rgb(93, 108, 208) 25%, transparent 75% 100%) border-box;
  -webkit-mask:
    linear-gradient(white 0 0) padding-box,
    linear-gradient(white 0 0);
          mask:
    linear-gradient(white 0 0) padding-box,
    linear-gradient(white 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: global-loading-spin 1s infinite linear;
}

.global-loading-text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  font-size: 36px;
  font-weight: 300;
}

@keyframes global-loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
